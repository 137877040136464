import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet blanc o blanc rosat amb punts més rojos de forma molt irregular, pot semblar-se a mig embut o a una copa, amb peu o sense, formant grups nombrosos. Té porus de 2-5 mm, blanquinosos, després rosats i color castanya, de rodons a allargats, formant una mena de làmines ondulades. Les espores blanques en massa, el·lipsoides, llises, de 4,5-6 x 3-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      